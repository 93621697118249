import React, { FunctionComponent } from 'react'
import { CoursesCardFactory } from './CoursesCardFactory'
import { Course, tagsList } from './CoursesMockData'
import { ToggleButton } from '../shared/Button/ToggleButton'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'
import Button from '../shared/Button/Button'
import theme from '../styling/theme'
import _ from 'lodash'

// Returns the course if it has one of the items in the filter array
// Does't return if it doesn't
function courseHasTags(course: Course, filters: string[]) {
  let result = false

  filters.forEach((f) => {
    const hyphenated = f.toLowerCase()
    const hyphenated2 = hyphenated.replace(' ', '-')
    if (course.acfCourse2.category === hyphenated2) {
      result = true
    }
  })
  return result
}

function getPercentageShown(count: number, total: number) {
  const ratio = ((count / total) * 100).toFixed(0)
  const result = `${ratio}%`

  return result
}

function shouldShowLoadMore(
  numberCardsShowing: number,
  numberFilteredCourses: number
) {
  return numberCardsShowing < numberFilteredCourses
}

interface CoursesLibraryProps {
  courses: any[]
}

const CoursesLibrary: FunctionComponent<CoursesLibraryProps> = ({
  courses,
}) => {
  const alphabetical = _.cloneDeep(courses)
  //@TODO

  const otherLength = courses.filter(
    (c) => c.acfCourse2.category === 'other' || null
  ).length

  alphabetical.sort((a, b) => {
    const nameA = a.title.toLowerCase(),
      nameB = b.title.toLowerCase()
    if (nameA < nameB)
      //sort string ascending
      return -1
    if (nameA > nameB) return 1
    return 0 //default return value (no sorting)
  })

  const loadingIncrement = 6
  const initalNumberToShow = 12
  const [numberCardsShowing, setNumberCardsShowing] = React.useState(
    initalNumberToShow
  )

   

  const [filters, setFilters] = React.useState<string[]>(['All'])
  const [filteredCourses, setFilteredCourses] = React.useState(alphabetical)

  //On mount, check to see if the user has any saved filters, if they do, apply them
  React.useEffect(() => {
    const filterFromStorage = window.sessionStorage.getItem('filters')
    if (filterFromStorage !== null && filterFromStorage !== undefined) {
      setFilters(filterFromStorage.split(','))
    }
  }, [])

  // Runs when filters array changes
  React.useEffect(() => {
    window.sessionStorage.setItem('filters', filters.join())
    //If the filter contains all -> show all
    if (filters.filter((f) => f === 'All').length > 0) {
      setFilteredCourses(alphabetical)
      setNumberCardsShowing(initalNumberToShow)
      return
    }
    // if the filter has something in it ->
    if (filters.length > 0) {
      // Filters the courses
      let result: Course[] = alphabetical.filter((course) =>
        courseHasTags(course, filters)
      )
      // If Other is selected. Add those course to the resutls too
      if (filters.filter((f) => f === 'Other').length > 0) {
        const otherCourses = alphabetical.filter(
          (course: Course) => course.acfCourse2.category === null
        )
        result = [...result, ...otherCourses]
      }
      setFilteredCourses(result)
      // Limit the max number of courses showing to the length of the filtered array
      if (numberCardsShowing > result.length) {
        setNumberCardsShowing(result.length)
        // if the filtered array is bigger than the number of cards showing
      } else {
        // Set the number showing to the number of results...
        setNumberCardsShowing(result.length)
        if (result.length > initalNumberToShow) {
          // ..Unless the number of results is bigger than the initial value -> then cap it at the inital value
          setNumberCardsShowing(initalNumberToShow)
        }
      }
    }
  }, [filters])
// console.log('tags')
// console.log(tagsList)
// console.log('courses')
// console.log(courses)
  return (
    <Container>
      <ButtonContainer>
        <ToggleButton
          keyword="All"
          filters={filters}
          key={'toggle-button-all'}
          callback={() => {
            setFilters(['All'])
          }}
        >
          All ({courses.length})
        </ToggleButton>
        {tagsList.map((v, i) => {
          if (v !== null) {
            const hyphenated = v.toLowerCase()
            const hyphenated2 = hyphenated.replace(' ', '-')

            return (
              <ToggleButton
                filters={filters}
                keyword={hyphenated2}
                key={'toggle-button-' + i}
                callback={() => {
                  const result = filters.filter((word) => word === hyphenated2)
                  if (result.length > 0) {
                    const withoutClicked = filters.filter(
                      (word) => word !== hyphenated2
                    )
                    if (withoutClicked.length > 0) {
                      setFilters(withoutClicked)
                    } else {
                      setFilters(['All'])
                    }
                  } else {
                    if (v !== null) {
                      const withoutAll = filters.filter(
                        (word) => word !== 'All'
                      )
                      setFilters([...withoutAll, hyphenated2])
                    }
                  }
                }}
              >
                {v + "  "} (
                {
                  courses.filter((c) => c.acfCourse2.category === hyphenated2)
                    .length
                }
                )
              </ToggleButton>
            )
          }
        })}

        {/* <ToggleButton
          keyword="Other"
          filters={filters}
          key={'toggle-button-other'}
          callback={() => {
            const result = filters.filter((word) => word === 'Other')
            if (result.length > 0) {
              const withoutClicked = filters.filter((word) => word !== 'Other')
              if (withoutClicked.length > 0) {
                setFilters(withoutClicked)
              } else {
                setFilters(['All'])
              }
            } else {
              const withoutAll = filters.filter((word) => word !== 'All')
              setFilters([...withoutAll, 'Other'])
            }
          }}
        >
          Other(
          {otherLength})
        </ToggleButton> */}
        <PaddingRight />
      </ButtonContainer>
      <MainGrid>
        <CoursesCardFactory
          courses={filteredCourses.slice(0, numberCardsShowing)}
        />

        {shouldShowLoadMore(numberCardsShowing, filteredCourses.length) && (
          <LoadMoreContainer>
            <ShowingMoreText>
              Showing <BoldBlueSpan>{numberCardsShowing}</BoldBlueSpan> of
              <BoldSpan> {filteredCourses.length} Results</BoldSpan>
            </ShowingMoreText>
            <LoadedIndicatorContainer>
              <LoadedIndicatorInner
                width={getPercentageShown(
                  numberCardsShowing,
                  filteredCourses.length
                )}
              />
            </LoadedIndicatorContainer>
            <div onClick={() => {
             
                if (
                  numberCardsShowing + loadingIncrement >
                  filteredCourses.length
                ) {
                  setNumberCardsShowing(filteredCourses.length)
                } else {
                  setNumberCardsShowing(numberCardsShowing + loadingIncrement)
                }
              }}>
            <Button
              secondary
              inactive={
              false
              }
              
            >
              Load more
            </Button>
            </div>
          </LoadMoreContainer>
        )}
      </MainGrid>
    </Container>
  )
}

export default CoursesLibrary

const Container = styled.section`
  padding: 30px 0 60px;

  @media (min-width: 1024px) {
    padding: 70px 0 110px;
  }
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 26px;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  overflow-x: scroll;

  @media (min-width: 768px) {
    padding: 0 3% 26px;
    z-index: 1;
  }
  @media (min-width: 1024px) and (max-width: 1199px) {
    overflow: auto;
    padding: 0 3% 30px;
  }
  @media (min-width: 1200px) {
    width: 90%;
    overflow: auto;
    max-width: 1140px;
    padding: 0 0 30px;
  }
`

const LoadMoreContainer = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  width: 100%;
  & a {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 768px) {
    padding-top: 37px;
  }
`
const ShowingMoreText = styled.div`
  text-align: center;
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
  @media (min-width: 1024px) {
    font-size: 20px;
    line-height: 30px;
  }
`
const BoldSpan = styled.span`
  font-weight: 900;
`
const BoldBlueSpan = styled.span`
  font-weight: 900;
  color: ${theme.brand.blue};
`
const LoadedIndicatorContainer = styled.div`
  width: 100%;
  max-width: 294px;
  margin: 0 auto 32px;
  height: 3px;
  background-color: ${theme.grey.keylineDark};
`
interface LoadingIndicatorInnerProps {
  width: string
}
const LoadedIndicatorInner = styled.div<LoadingIndicatorInnerProps>`
  width: ${(props) => props.width};
  background-color: ${theme.brand.blue};
  height: 3px;
`
const PaddingRight = styled.div`
  width: 25px;
  height: 10px;
  background: white;
  display: none;

  @media (max-width: 767px) {
    display: block;
    padding: 0 12px 0 0;
  }
`

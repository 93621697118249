import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

interface IPill {
  children: any
  color: string
  marginBottom: string
}

const Pill = ({ children, color, marginBottom }: IPill) => {
  return (
    <TagPill color={color} marginBottom={marginBottom}>
      {/* {course.tag ? course.tag.toUpperCase() : 'OTHER'} */}
      {children}
    </TagPill>
  )
}

export default Pill

const TagPill = styled.div<IPill>`
  background-color: ${(props) => props.color};
  font-size: 9px;
  line-height: 10px;
  color: ${theme.text.white};
  display: inline-block;
  border-radius: 15px;
  padding: 3px 8px;
  /* height: 16px; */
  margin-bottom: ${(props) => props.marginBottom};
`

import React from 'react'
import TrackVisibility from 'react-on-screen'
import Layout from '../components/Layout'

import PromotionBannerAnimation from '../components/PromotionBannerAnimation'

import { parse } from '@wordpress/block-serialization-default-parser'
import CoursesLibrary from '../components/CourseCollection/CoursesLibrary'

import { graphql } from 'gatsby'
import { LazyBlocksFactory, LazyBlocksFactoryNoParse } from '../components/LazyBlocksFactory/LazyBlocksFactory'




interface Props {
  data: any
  pageContext: any
}
const Courses = ({ data, pageContext }: Props) => {
  const courses = data.allWpCourse.edges.map((v) => v.node)
  const { wpPage } = data
  const renderedBlocks = LazyBlocksFactory(wpPage.lazy_data, wpPage.title)
// console.log('lazy')
// console.log(renderedBlocks)





  return (
    <Layout>
      {renderedBlocks.map((v,i) => {
        if(i===0){
          return(
            <>
            <p></p>
            {v}
            <CoursesLibrary courses={courses} />
            </>
            )
        }
        else{

        
        return(v)}
        })}
      {/* <TheCourseNumbers data={courseNumberData} />
      <WhyBobCards data={whybobData} /> */}
      
    </Layout>
  )
}

export const query = graphql`
  query CoursesLibraryQuery {
    allWpCourse {
      edges {
        node {
          id
          acfCourse2 {
            accreditations
            description
            category
          }
          title
          slug
        }
      }
    }
    wpPage(title: { eq: "Courses" }) {
      id
      lazy_data
      title
    }
  }
`

export default Courses

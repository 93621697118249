import React from 'react'
import { Course } from './CoursesMockData'
import styled from 'styled-components'
import theme from '../styling/theme'
import TagPill from '../shared/TagPill'
import { appendUrlPartnerTracking } from '../../util/url'

import Arrow from '../../images/link-arrow.svg'
import { Link } from 'gatsby'

interface CoursesCardFactoryProps {
  courses: any[]
}
export function CoursesCardFactory({ courses }: CoursesCardFactoryProps) {
  return (
    <CardContainer>
      {courses.map((v, i) => {
        return <CoursesCard course={v} key={'course-card-' + i} />
      })}
    </CardContainer>
  )
}

interface CoursesCardProps {
  course: Course
}

function CoursesCard({ course }: CoursesCardProps) {
  let color = '#3D3D3D'
  const [charLimit, setCharLimit] = React.useState(134)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + '...'
  }

  switch (course.acfCourse2.category) {
    case 'cyber-security':
      color = '#00A3C7'
      break
    case 'compliance':
      color = '#66C1BF'
      break
    case 'social-engineering':
      color = '#C70F55'
      break
    case 'data-protection':
      color = '#F7A823'
      break
    case 'general':
      color = '#3D3D3B'
      break
    case 'gdpr':
      color = '#623594'
      break
  }

  return (
    <Card
      color={color}
      to={appendUrlPartnerTracking(`/courses/${course.slug}`)}
    >
      <CardContents>
        <TagPill color={color} marginBottom="20px">
          {course.acfCourse2.category
            ? course.acfCourse2.category.toUpperCase()
            : 'OTHER'}
        </TagPill>
        <CourseTitle>{course.title}</CourseTitle>
        <CourseDescription>
          {formatText(course.acfCourse2.description)}
        </CourseDescription>
        {course.certificate && (
          <CourseCertification className="legal-text">
            {course.certificate}
          </CourseCertification>
        )}
      </CardContents>
      <ArrowContainer>
        <ArrowImage src={Arrow} alt="back" />
      </ArrowContainer>
    </Card>
  )
}

const CardContainer = styled.div`
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  padding-top: 28px;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 30px;
  border-top: 1px solid ${theme.grey.keyline};

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 50px;
    grid-row-gap: 30px;
    grid-column-gap: 30px;
  }
`
const Card = styled(Link)`
  transition: all 0.2s;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fafafa;
  border-top: 3px solid ${(props) => props.color};
  border-left: 1px solid #fafafa;
  border-bottom: 1px solid #fafafa;
  border-right: 1px solid #fafafa;
  text-decoration: none;
  font-family: Montserrat;
  &:hover {
    -webkit-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.25);
    border-left: 1px solid ${(props) => props.color};
    border-bottom: 1px solid ${(props) => props.color};
    border-right: 1px solid ${(props) => props.color};
    text-decoration: none;
  }
  &:hover > #HoverBar {
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    max-width: 46rem;
    padding: 0;
  }

  &::after {
    content: '';
    width: 0px;
    height: 5px;
    background-color: ${(props) => props.color};
    position: absolute;
    left: 0px;
    top: -2px;
    right: 0;
    transition: all 0.3s ease;
  }

  &:hover::after {
    width: calc(100% + 1px);
  }
`
const HoverBar = styled.div`
  width: 0px;
  height: 5px;
  background-color: ${(props) => props.color};
  transition: all 0.4s;
  position: relative;
  top: -2px;
  left: 0px;
  z-index: 5;
`
const CardContents = styled.div`
  width: 100%;
  padding: 40px 30px 36px;
  background: ${theme.background.hpHeader};
  @media (min-width: 1024px) {
    padding: 40px 30px 26px;
  }
`

const CourseDescription = styled.p`
  font-weight: 300;
`
const CourseCertification = styled.p`
  font-weight: bold;
  padding-top: 22px;
`
const CourseTitle = styled.h5`
  padding-bottom: 12px;
`
const ArrowContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 20px;
`
const ArrowImage = styled.img`
  width: 20%;
`

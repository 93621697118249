import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../styling/theme'

interface ToggleButtonProps {
  filters?: string[]
  keyword?: string
  callback: () => void
}

export const ToggleButton: FunctionComponent<ToggleButtonProps> = ({
  filters,
  children,
  keyword,
  callback,
}) => {
  const [toggled, setToggle] = React.useState(false)

  useEffect(() => {
    if (filters.filter((f) => f === keyword).length > 0) {
      setToggle(true)
    } else {
      setToggle(false)
    }
  }, [filters])

  return (
    <Container
      toggled={toggled}
      onClick={(event) => {
        setToggle(!toggled)
        callback()
      }}
    >
      {children}
    </Container>
  )
}

interface ContainerProps {
  toggled: boolean
}

const Container = styled.div<ContainerProps>`
  padding: 9px 16px;
  margin-right: 10px;
  color: ${theme.brand.red};
  border: 1px solid ${theme.brand.red};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  border-radius: 5px;
  font-size: 12px;
  line-height: 15px;
  font-weight: 300;
  background-color: #ffffff;
  transition: all 0.1s;
  cursor: pointer;
  &:hover {
    background-color: ${theme.brand.red};
    color: white;
  }
  ${(props) =>
    props.toggled &&
    `
      background-color: ${theme.brand.red};
      color: white;
      &:hover {
        background-color: ${theme.brand.redHover};
        color: white;
  }
    `}

  @media (min-width: 1024px) {
    padding: 11px 16px;
    font-size: 13px;
    line-height: 16px;
  }
`

// export type Tag =
//   | 'Cyber Security'
//   | 'Data Protection'
//   | 'Compliance'
//   | 'Social Engineering'
//   | null

export interface Course {
  title: string
  description: string
  tag: string
  certificate?: string
  slug: string
}

export const tagsList = [
  'Cybersecurity',
  'Social Engineering',
  'Data Protection',
  'Compliance',
  'General',
  'GDPR',
]
